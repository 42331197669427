* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --light-gray-color: #DDDBDE;
  --light-steel-color: #aed6ff;
  --dark-gray-color: #656E77;
  --dark-steel-color: #000000;
  --beautiful-blue-color: #66fcf1;
  --dark-beautiful-blue-color: #1aa49b;
}


html,
body {
  background-color: black;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
  font-family: monospace;
  display: grid;
  grid-template-rows: 80px 1fr;
}

nav {
  font-size: 20px;
  background-color: var(--dark-steel-color);
  color: var(--dark-beautiful-blue-color);
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
  column-gap: 50px;
  align-items: center;
  white-space: nowrap;
}

nav > a { 
  transition: 0.7s;
}

nav > a:hover {
  transition: 0.7s;
  transform: scale(1.1) translateX(10px);
  color: var(--beautiful-blue-color);
}

.main-link {
  font-size: 25px;
  grid-column: 2 / 5;
}

a {
  text-decoration: none;
  color: inherit;
}

.App {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 15% 85%;
  position: relative;
}

.App:after {
  background-image: url("./components/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  content: "";
  filter: grayscale(10%) brightness(20%) contrast(95%);
}

.confident-text {
  z-index: 1;
  color: var(--beautiful-blue-color);
  grid-row: 2 / 3;
  display: grid;
  padding-left: 10%;
  grid-template-rows: 200px 200px;
}

.confident-text > h2 {
  font-size: 3.2rem;
}

.confident-text > p {
  width: 70%;
  font-size: 2rem;
}

.about {
  color: white;
}

.browse-a {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.toShop {
  grid-column: 1 / 2;
  align-self: center;
  justify-self: end;
  border: 7px solid black;
  width: 300px;
  height: 80px;
  font-size: 1.9rem;
  color: white;
  background: transparent;
  background-image: url("./components/waves.png");
  background-repeat: repeat-x;
  background-position: 0 -100%;
  transition: 1.2s ease;
  font-weight: bold;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  text-shadow: 0 3px 5px rgba(0,0,0,0.25);
  white-space: nowrap;
}

.toShop:hover {
  transition: 1.2s ease;
  background-position: 300% 100%;
  transform: translateX(20px);
}



.pop {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background-color: var(--light-gray-color);
  width: 300px;
  height: 30px;
  z-index: 3;
  position: fixed;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  right: 10%;
  bottom: 0;
}

.style-nav {
  color: var(--beautiful-blue-color);
}
